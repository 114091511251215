.info-window {
  position: relative;
  padding: 20px;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 80%; /* Set maximum height for the info window */
  overflow-y: auto; /* Enable vertical scrolling */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.button-group button {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.button-group button.active {
  background-color: #0056b3;
}

.image-gallery {
  margin-bottom: 15px; /* Add margin bottom */
}

.gallery-item {
  margin-bottom: 20px;
}

.gallery-image {
  width: 100%;
  border-radius: 8px;
  cursor: pointer; /* Add cursor pointer to indicate clickable image */
}

.comment-section {
  text-align: left;
}

.comment-text {
  font-size: 1rem;
}

.comment-author {
  font-size: 0.875rem;
  color: #666;
}

.separator {
  margin: 15px 0;
  border-bottom: 1px solid #ccc;
}

.content {
  min-height: 200px; /* Ensure the content area has a minimum height */
}

.info-name {
  font-weight: bold;
}

.info-address,
.info-phone,
.info-price-range {
  margin: 5px 0;
}

.info-opening-hours {
  padding-left: 20px;
  margin: 5px 0;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 80%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.fixed-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.modal-nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.comment-section {
  text-align: center;
  margin-top: 10px;
}

.delete-button {
  padding: 10px;
  background-color: #FF0000; /* Red background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #cc0000; /* Darker red on hover */
}