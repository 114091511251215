.photo-gallery {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.9); /* Background white */
  width: 100%;
  height: 300px; /* Fixed height */
  border-radius: 8px;
  overflow: hidden;
}

.info-photo {
  max-width: 100%;
  max-height: 100%;
}

.gallery-button {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-button-left {
  left: 10px;
}

.gallery-button-right {
  right: 10px;
}

.info-name {
  font-size: 1.5em;
  margin: 10px 0;
}

.info-address, .info-phone, .info-price-range {
  margin: 5px 0;
}

.info-price-range:before {
  content: "Average Price: ";
}

.info-phone:before {
  content: "Phone: ";
}

.info-opening-hours-title {
  font-size: 1.2em;
  margin: 10px 0 5px;
}

.info-opening-hours {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

.info-opening-hours li {
  margin: 3px 0;
}

.google-maps-link {
  display: block;
  margin: 10px 0;
  color: #007BFF;
  text-decoration: none;
}

.google-maps-link:hover {
  text-decoration: underline;
}

.info-textarea {
  width: 95%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.info-file-upload {
  margin: 10px 0;
}

.info-file-label {
  display: block;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
}

.info-file-label:hover {
  background-color: #0056b3;
}

.info-file-input {
  display: none;
}

.upload-feedback {
  margin: 5px 0;
  font-size: 0.9em;
  color: #333;
}

.info-save-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.info-save-button:hover {
  background-color: #218838;
}

.close-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}